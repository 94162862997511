<template>
  <div id="MyQuesAnswer">
    <van-tabs @click="onClick" v-model="activeName">
      <van-tab title="我的提问" name="a">
        <!-- <div class="mainTop">
          <div @click="toDetail">
            <p class="second">保监会公布上半年保险运行情况：行业原保费增长为23%保监会公布上半年保险运行情况：行业原保费增长为23%</p>
            <p class="third">
              <span>2020/11/25</span> ·
              <span>1 回答</span> ·
              <span>252 围观</span>
            </p>
          </div>
          <div @click="toDetail">
            <p class="second">保监会公布上半年保险运行情况：行业原保费增长为23%保监会公布上半年保险运行情况：行业原保费增长为23%</p>
            <p class="third">
              <span>2020/11/25</span> ·
              <span>1 回答</span> ·
              <span>252 围观</span>
            </p>
          </div>
        </div> -->
      </van-tab>
      <van-tab title="我的回答" name="b">
        <!-- <div class="hotMain">
          <div @click="toDetail">
            <p class="second">保监会公布上半年保险运行情况：行业原保费增长为23%保监会公布上半年保险运行情况：行业原保费增长为23%</p>
            <div class="first"><div><img src="../../assets/image/home/image1.png" /><span>杨轩</span>回答了</div></div>
            <p class="third"><img src="../../assets/image/home/answer.png" />11月25日保监会公布2020年上半年保险业绩数据报告显示为行业原保费 增长23%，同比增长12%，净利润715.1亿元，同比增比增长12%，净利润715.1亿元，同比增产</p>
            <div class="forth">
              <div><img src="../../assets/image/home/image1.png" /><img src="../../assets/image/home/image1.png" /><img src="../../assets/image/home/image1.png" /> 等252人已围观</div>
              <div>
                <span>2020/11/25 11:58</span>
              </div>
            </div>
          </div>
          <div @click="toDetail">
            <p class="second">保监会公布上半年保险运行情况：行业原保费增长为23%保监会公布上半年保险运行情况：行业原保费增长为23%</p>
            <div class="first"><div><img src="../../assets/image/home/image1.png" /><span>杨轩</span>回答了</div></div>
            <p class="third"><img src="../../assets/image/home/answer.png" />11月25日保监会公布2020年上半年保险业绩数据报告显示为行业原保费 增长23%，同比增长12%，净利润715.1亿元，同比增比增长12%，净利润715.1亿元，同比增产</p>
            <div class="forth">
              <div><img src="../../assets/image/home/image1.png" /><img src="../../assets/image/home/image1.png" /><img src="../../assets/image/home/image1.png" /> 等252人已围观</div>
              <div>
                <span>2020/11/25 11:58</span>
              </div>
            </div>
          </div>
        </div> -->
      </van-tab>
      <van-tab title="待回答" name="c">
        <!-- <div class="dAnswer">
          <div class="dItem">
            <div class="first">
              <div>
                <img :src="quizDetail.user.headimgurl ?quizDetail.user.headimgurl :'../../assets/image/home/image1.png'"  />
                <img src="../../assets/image/home/image1.png" />
                <span>徐磊</span>向您提问期待您的回答
              </div>
              <span>2020/11/25 11:58</span>
            </div>
            <p class="second">互联网保险业务做B端还是C端？哪个方向相对而言更好？大 佬有什么建议呢？</p>
            <div class="forth">
              <div><span>互联</span></div>
              <div>
                <span><span>1</span>回答</span>
                <span><span>1</span>围观</span>
                <b>写回答</b>
              </div>
            </div>
          </div>
        </div> -->
      </van-tab>
      <van-tab title="待修改" name="d">
      </van-tab>

    </van-tabs>


    <div ref="topMost"></div>
    <van-list 
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="mainTop" v-show="activeName == 'a' ||!activeName">
        <div @click="toDetail(i.id,i.is_replied)" v-for="(i,item) in getDocList" :key="item">
          <p class="second">{{i.question}}</p>
          <p class="third">
            <span>{{Global.fromatTimestamp(i.created_at).split(' ')[0]}}</span> ·
            <span>{{i.is_replied == 1?'1':'0'}} 回答</span>
             <!-- · -->
            <!-- <span>{{i.circusee_count}} 围观</span> -->
          </p>
        </div>
      </div>
      <div class="hotMain" v-show="activeName == 'b'">
        <div @click="toDetail(i.id)" v-for="(i,item) in getDocList" :key="item">
          <p class="second">{{i.question}}</p>
          <div class="first" v-if="i.user && i.member_info">
            <div>
              <img :src="i.user.headimgurl ?Global.changeImg(i.user.headimgurl) :'../../assets/image/home/image1.png'"  />
              <span>{{i.member_info.name}}</span>回答了
            </div>
          </div>
          <p class="third"><img src="../../assets/image/home/answer.png" />{{i.answer}}</p>
          <div class="forth">
            <div>
              <span>
                {{i.upvote}}
                <van-icon   name="good-job-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#FF5562" />
              </span>
              <span >
                {{i.commentCount}} 
                <van-icon  name="comment-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#3890FF" />
                <!-- <img v-for="(j,items) in i.circusee"  :key="items" :src="Global.changeImg(j.head_img.headimgurl)" /> 等{{i.circusee_count}}人已围观 -->
              </span>
              <!-- <img v-for="(j,itemj) in circusee" :key="itemj" :src="Global.changeImg(j.head_img.headimgurl)" />等{{i.circusee_count}}人已围观 -->
            </div>
            <div>
              <span>{{Global.fromatTimestamp(i.created_at)}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="dAnswer" v-show="activeName == 'c' ">
        <div class="dItem"  @click="toAnswer(i.id)" v-for="(i,item) in getDocList" :key="item">
          <div class="first" v-if="i.quiz_user && i.quiz_member_info">
            <div>
              <img :src="i.quiz_user.headimgurl ?Global.changeImg(i.quiz_user.headimgurl) :'../../assets/image/home/image1.png'"  />
              <span>{{i.quiz_member_info.name}}</span>向您提问期待您的回答
            </div>
            <span>{{Global.fromatTimestamp(i.created_at)}}</span>
          </div>
          <p class="second">{{i.question}}</p>
          <div class="forth" v-if="i.tag">
            <div><span>{{i.tag.name}}</span></div>
            <div>
              <span><span>0</span>回答</span>
              <!-- <span><span>{{i.circusee_count}}</span>围观</span> -->
              <b>写回答</b>
            </div>
          </div>
        </div>
      </div>
      <div class="hotMain" v-show="activeName == 'd'">
        <div @click="toDetail(i.id)" v-for="(i,item) in getDocList" :key="item">
          <p class="second">{{i.question}}</p>
          <div class="first" v-if="i.user && i.member_info">
            <div>
              <img :src="i.user.headimgurl ?Global.changeImg(i.user.headimgurl) :'../../assets/image/home/image1.png'"  />
              <span>{{i.member_info.name}}</span>回答了
            </div>
          </div>
          <p class="third"><img src="../../assets/image/home/answer.png" />{{i.answer}}</p>
          <div class="forth">
            <div>
              <span>
                0
                <van-icon   name="good-job-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#FF5562" />
              </span>
              <span >
                0
                <van-icon  name="comment-o" style="font-size:0.15rem;margin-left:0.05rem"  color="#3890FF" />
                <!-- <img v-for="(j,items) in i.circusee"  :key="items" :src="Global.changeImg(j.head_img.headimgurl)" /> 等{{i.circusee_count}}人已围观 -->
              </span>
              <!-- <img v-for="(j,itemj) in circusee" :key="itemj" :src="Global.changeImg(j.head_img.headimgurl)" />等{{i.circusee_count}}人已围观 -->
            </div>
            <div>
              <span>{{Global.fromatTimestamp(i.created_at)}}</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  name: "MyQuesAnswer",
  data() {
    return {
      activeName:'a',
      getDocList:'',//列表
      loading:false,
      finished:false,
      next_page_url:'',
    };
  },
  mounted() {
    this.axios({
      method: 'PATCH',
      url:'/notify/notifyRead',
      data:{
        type:1
      },
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    })
    this.activeName = this.$route.query.activeName
    var url;
    if(this.activeName == 'a' || !this.activeName){
      url = '/quiz/userQuiz'
    }else if(this.activeName == 'b'){
      url = '/quiz/userAnswer'
    }else if(this.activeName == 'c'){
      url = '/quiz/stayAnswer'  
    }else if(this.activeName == 'd'){
      url = '/quiz/notAuditAnswer'  
    }
    this.axios({
      method: 'GET',
      url: url,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.getDocList = res.data.data.data
        this.loading = false
        this.finished = false
        this.next_page_url = res.data.data.next_page_url
      }
    })
  },
  methods: {
     firstLoad(){
      window.scrollTo(0,0)
      var url;
      console.log(this.activeName,!this.activeName)
      if(this.activeName == 'a' || !this.activeName){
        url = '/quiz/userQuiz'
      }else if(this.activeName == 'b'){
        url = '/quiz/userAnswer'
      }else if(this.activeName == 'c'){
        url = '/quiz/stayAnswer'  
      }else if(this.activeName == 'd'){
        url = '/quiz/notAuditAnswer'  
      }
      this.finished = false
      this.axios({
        method: 'GET',
        url: url,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            this.getDocList = res.data.data.data;
            this.next_page_url = res.data.data.next_page_url
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    onLoad(){
      if(this.next_page_url){
        this.axios({
          method: 'GET',
          url: this.next_page_url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    onClick(name, title){
      console.log(name,title)
      this.activeName = name
      this.firstLoad()
    },
    toAnswer(id){
      this.$router.push({
        path: '/QuestionAnswer?id='+id,
      })
    },
    toDetail(id,is_replied){
      console.log(this.activeName)
      if(is_replied == 1 || this.activeName == 'b' ){
        this.$router.push({
          path: '/QuestionDetail?id='+id,
        })
      }if(this.activeName == 'd'){
        this.$router.push({
          path: '/QuestionAnswerEdit?id='+id,
        })
      } else{
        this.$toast('暂时没有回答')
      }
    },
  },
};
</script>
<style lang="less" scoped>
#MyQuesAnswer {
  .dAnswer{
    padding: 0.15rem;
    border-bottom: .1px solid #5C5D6E61;
    >div:last-child{
      border-bottom: none;
    }
    .dItem{
      .forth{
        >div:last-child{
          display: flex;
          >b{
            height: 0.22rem;
            border-radius: 0.22rem;
            margin-left: 0.15rem;
            padding: 0 0.12rem;
            background: #3890FF;
            color: #F6F7FD;
            font-size: 0.12rem;
            font-weight: normal;
            line-height: 0.22rem;
          }
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            margin-left: 0.2rem;
            >span{
              color: #F6F7FD;
              font-size: 0.12rem;
              margin-right: 0.05rem;
            }
          }
        }
        >div:first-child{
          color: #989AB1;
          display: flex;
          align-items: center;
          >span{
            color: #9091A3;
            padding: 0 0.05rem;
            background: #3D404F;
            border-radius: 0.02rem;
            font-size: 0.12rem;
          }
        }
      }
      .second{
        color: #F6F7FD;
        font-size: 0.14rem;
        margin: 0 0 0.12rem;
      }
      .first{
        margin: 0.11rem 0 0.12rem;
        display: flex;
        justify-content: space-between;
        >div{
          color: #727387;
          font-size: 0.12rem;
          display: flex;
          align-items: center;
          >span{
            margin: 0 0.1rem 0 0.08rem;
          }
          >img{
            width: 0.19rem;
            border-radius: 50%;
          }
        }
        >span{
          color: #727387;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
       
      }
    }
  }
  .mainTop{
    >div{
      padding: 0.15rem;
      border-bottom: .1px solid #5C5D6E61;
      .third{
        color: #989AB1;
        font-size: 0.12rem;
        line-height: 0.17rem;
      }
      .second{
        color: #F6F7FD;
        font-size: 0.14rem;
        margin: 0 0 0.15rem;
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .hotMain{
    padding: 0 0.15rem;
    >div{
      padding: 0.18rem 0;
      border-bottom: .1px solid #5C5D6E61;
      .forth{
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
          }
        }
        >div:first-child{
          color: #989AB1;
          display: flex;
          align-items: center;
          font-size: 0.12rem;
          >span{
            margin-right: 0.1rem;
            display: flex;
            align-items: center;
          }
          >img{
            width: 0.14rem;
          }
          >img:nth-child(n+2){
            margin-left: -0.03rem;
          }
          >img:last-child{
            margin-right: 0.05rem;
          }
        }
      }
      .third{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #989AB1;
        font-size: 0.12rem;
        line-height: 0.17rem;
        margin-bottom: 0.15rem;
        >img{
          width: 0.14rem;
          display: inline-block;
          line-height: 0.17rem;
          margin-bottom: -0.02rem;
        }
      }
      .second{
        color: #F6F7FD;
        font-size: 0.14rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .first{
        margin: 0.11rem 0 0.12rem;
        >div{
          color: #727387;
          font-size: 0.12rem;
          display: flex;
          align-items: center;
          >span{
            margin: 0 0.1rem 0 0.08rem;
          }
          >img{
            width: 0.19rem;
            border-radius: 50%;
          }
        }
        >span{
          color: #727387;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
</style>